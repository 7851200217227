import { useEffect, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { MenuIcon } from '@heroicons/react/outline'
import StudentSidebars from './StudentSidebars'
import { getCompletedChapters } from '../common/api'
import { useRecoilValue } from 'recoil';
import { authAtom } from '../state'
// import { recordPayment } from '../common/api'

const StudentApp = () => {

  const auth = useRecoilValue(authAtom)

  const [ completedChapters, setCompletedChapters ] = useState(new Set([]))
  const [ sidebarOpen, setSidebarOpen ] = useState(false)

  const [ searchParams, setSearchParams ] = useSearchParams()


  const toggleSidebar = (open) => {
    setSidebarOpen(open)
  }

  // useEffect(() => {
  //   if (searchParams.has('payment_intent') && searchParams.has('payment_intent_client_secret')) {
  //     const pi = searchParams.get('payment_intent');
  //     const pics = searchParams.get('payment_intent_client_secret');

  //     setSearchParams({})
  //     if (pi && pics) {
  //       recordPayment(auth.id, pi, pics)
  //       .then(() => {
  //         console.log('payment recorded...')
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //     }
  //   }

  // }, [])

  useEffect(() => {
    getCompletedChapters(auth.id)
    .then((chapters) => {
      // console.log(chapters)
      setCompletedChapters(new Set(chapters))
    })
    .catch((err) => {
      localStorage.removeItem('user')
      console.log(err)
    })
  }, [])

  return (
    <>
      <div>


        

        <StudentSidebars
          toggleSidebar={toggleSidebar}
          sidebarOpen={sidebarOpen}
          completedChapters={completedChapters}
          setCompletedChapters={setCompletedChapters}
        />

        {/* <MobileSidebar
          toggleSidebar={toggleSidebar}
          sidebarOpen={sidebarOpen}
          navItems={NavItems}
        />

        <DesktopSidebar navItems={NavItems} /> */}

        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* <div className="absolute top-2 right-2" id="google_translate_button_mobile"></div> */}
          </div>
          <main className="flex-1 mb-10 bg-gray-50">

            <Outlet />
            
          </main>
        </div>
      </div>
    </>
  )

}

export default StudentApp;