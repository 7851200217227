import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAtom } from './state';
import { useUserActions } from './actions/user.actions';

const PrivateRoute = ({ userType, children }) => {

    const auth = useRecoilValue(authAtom)
    const userActions = useUserActions()
    
    if (auth?.type == "clerk" || auth?.type == "super_admin" || auth?.type == "admin" || auth?.type == "student") {

        return children;

        // if (userType == 'student') {
        //     if (auth.payment_at) {
        //         return children;
        //     } else {
        //         return <Navigate to='/pay' state={{ studentId: auth.id }} />
        //     }
        // } else {
        //     return children;
        // }

        // if (userType == 'student') {
        //     // check if they have paid...
        //     console.log(auth)
        //     if (!auth.payment_at) {
        //         userActions.login(auth.last_name, auth.dl_number)
        //         .then((user) => {
        //             if (user.payment_at) {
        //                 return children;
        //             } else {
        //                 return <Navigate to='/pay' />
        //             }
        //         })
        //     } else {
        //         return children;
        //     }
        //     // if (auth.payment_at) {
        //     //     return children;
        //     // } else {
        //     //     return <Navigate to='/pay' />
        //     // }

    }

    if (userType == 'admin') {
        localStorage.removeItem('user')
        return <Navigate to='/admin-login' />;
    }
    localStorage.removeItem('user')
    return <Navigate to='/login' />;

}

export default PrivateRoute;