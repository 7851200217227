import { useSetRecoilState } from 'recoil'
import axios from 'axios'
import { authAtom } from '../state'

const useUserActions = () => {

  const setAuth = useSetRecoilState(authAtom);

  const login = (username, password, userType) => {
    return new Promise((resolve, reject) => {
      const userPostData = {
        [userType == 'admin' ? 'username' : 'last_name']: username,
        [userType == 'admin' ? 'password' : 'dl_number']: password
      }
      axios.post(`/login/${userType}`, userPostData)
      .then((res) => {
        console.log(res.data)
        
        if (res.data.status == 'SUCCESS') {
          const user = { ...res.data.user, type: userType==='student' ? 'student' : res.data.user.type }
          localStorage.setItem('user', JSON.stringify(user))
          setAuth(user)
          resolve(user)
        } else {
          reject('Invalid login')
        }

      })
      .catch((err) => reject(err))
    })
  }

  const logout = () => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('user')
      setAuth(null)
      resolve()
    })
  }

  return {
    login,
    logout
  }

}

export { useUserActions }