import React from 'react';
import ReactDOM from 'react-dom';
import './input.css';
import App from './App';
import { RecoilRoot } from 'recoil';
// import { QuizProvider } from './Components/Quiz/QuizContext';
// import { StateProvider } from './StateProvider';
// import { initialState, reducer } from './reducer';



ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

