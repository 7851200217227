import React, { useState } from "react"
import { useNavigate, Link, useLocation, Navigate } from "react-router-dom"
import Axios from 'axios'
import { authAtom } from "../state"
import { useRecoilValue } from 'recoil'
import { useUserActions } from "../actions/user.actions"
import { getCertificate } from "../common/api"

export default function Login({ admin }) {
  const [ error, setError ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ certificates, setCertificates ] = useState(null)
  
  const [ loginData, setLoginData ] = useState({
    username: '',
    password: ''
  })
  
  const navigate = useNavigate()

  const location = useLocation()

  const userType = (admin || location.pathname == '/admin-login') ? 'admin' : 'student';

  const auth = useRecoilValue(authAtom)

  const handleChange = (e) => {
		const value = e.target.value
		setLoginData({
			...loginData,
			[e.target.name]: value
		});
	};

  const handleLogin = () => {
    setLoading(true);
    userActions.login(loginData.username, loginData.password, userType)
    .then((user) => {
      if (userType == 'student') {
        if (!user.payment_at) {
          // userActions.logout()
          navigate('/pay', { state: { studentId: user.id }})
        } else {
          if (user.completed_at) {
            console.log('getting certificate')
            getCertificate(user.id)
            .then((certs) => {
              if (certs && certs.url !== null) {
                setCertificates(certs)
              }
            })
            navigate('/certificate')
          } else {
            navigate('/')
          }
          
        }
      } else {
        navigate('/admin/students')
      }

      // navigate(userType == 'admin' ? '/admin' : '/')

    })
    .catch((err) => {
      
      setError('Invalid username or password')
      setLoading(false)
    })
  }
  
  const userActions = useUserActions();

  if (auth?.id) return auth?.type == 'admin' ? <Navigate to='/admin' /> : <Navigate to='/' />

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-20 w-auto"
            src="https://sd-site-assets.s3.us-west-2.amazonaws.com/images/620d71fcf708f14d9e5d4c46_sd-logo.png"
            alt="Superior Driver"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
          {
            userType == 'student' &&
              <p className="mt-2 text-center text-sm text-gray-600">
                Or{' '}
                <Link to="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                  click here to register an account
                </Link>
              </p>
          }
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6">
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  { userType == 'admin' ? 'Username or Email' : 'Last Name' }
                </label>
                <div className="mt-1">
                  
                  <input
                    onChange={handleChange}
                    id="username"
                    name="username"
                    type="text"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  { userType == 'admin' ? 'Password' : "Driver's License Number" }
                </label>
                <div className="mt-1">
                  <input
                    onChange={handleChange}
                    id="password"
                    name="password"
                    type={userType == 'admin' ? 'password' : 'text'}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                
                
              </div>

              <div className="flex items-center justify-between">
                {/* <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div> */}

                {/* <div className="text-sm">
                  <Link to="/reset-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </Link>
                </div> */}
              </div>

              <div>
                <button
                  onClick={handleLogin}
                  disabled={loading}
                  type="button"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  { loading ? 'Loading...' : 'Sign in' }
                </button>
                
              </div>

              {
                error &&
                  <p className="text-red-500">
                    {error}
                  </p>
              }
            </form>
          </div>
          {
            userType == 'student' &&
              <p className="p-4 flex justify-end">
                <Link to="/admin-login" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Admin Login
                </Link>
              </p>
          }
          {
            userType == 'admin' &&
              <p className="p-4 flex justify-end">
                <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Student Login
                </Link>
              </p>
          }
        </div>
      </div>
    </>
  )

}
  