import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react"
import classNames from '../common/twClassNames'
import { ArrowRightIcon, CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/solid'
import PageWrapper from "../common/PageWrapper"
import { Link, Navigate, useLocation } from "react-router-dom";
import { completeChapter } from "../common/api";

const Score = () => {

  const location = useLocation()
  console.log(location.state.quizResults)
  const submissionId = location.state?.quizResults?.submissionId
  const status = location.state?.quizResults?.status
  const score = location.state?.quizResults?.score
  const questionDisplay = location.state?.quizResults?.questionDisplay
  const chapterId = parseInt(location.state?.quizResults?.chapterId)
  const nextChapter = chapterId < 4 ? chapterId + 1 : -1
  const nextChapterPath = chapterId > 0 ? chapterId.toString() : 'final'
  const studentId = location.state?.studentId;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (status == 'PASS') {
      completeChapter(studentId, chapterId);
    }
  }, [])


  if (!location.state?.quizResults) return <Navigate to='/' />

  
  return (
    <PageWrapper title={`Chapter ${chapterId == 6 ? 'Final Exam' : `${chapterId} + Quiz`} Results`}>
      <>
        {
          status == 'PASS' &&
            <h3 className="mt-3 font-medium text-lg">
              Congratulations! You have passed the {chapterId == 6 ? 'final exam' : `quiz for Chapter ${chapterId}.`}<br/>
              Your score: <span className="text-green-600">{score}/{chapterId == 6 ? '20' : '10'}</span>
            </h3>
        }
        {
          (status == 'PASS' && chapterId == 6) &&
            <>
              <p className="mt-3">
                We're working on your certificate of completion now. Once it's ready, we'll send it to
                you and your court clerk in an email.
                <br/>
                If you haven't heard from us in the next 24 hours, please send an email to <a href="#">email@email.com</a>.
              </p>
            </>
        }
        {
          status == 'FAIL' &&
            <h3 className="mt-3 font-medium text-lg">
              Unfortunately, you did not answer enough questions correctly.<br/>
              Please review the correct answers below and try again.<br/>
              Your score: <span className="text-red-500">{score}/{chapterId == 6 ? '20' : '10'}</span>
            </h3>
        }
        <div className="mt-4">
          {
            questionDisplay.map(q => (
              <div
                className={classNames(
                  'bg-white border-2 px-4 py-6 shadow-md sm:p-6 sm:rounded-lg mt-4 border-gray-100',
                )}
              >
                <h2 className="mt-1 mb-2 text-lg text-gray-900">
                  {q.question}
                </h2>
                <div className="space-y-4">
                  {
                    q.answers.map(a => (
                      <div className="">
                        <div className={classNames(
                          'relative block bg-white rounded-lg shadow-sm px-6 py-4 sm:flex sm:justify-between focus:outline-none',
                          (a.correct) ? 'border-2 border-green-500' : '',
                          (a.selected && !a.correct) ? 'border-2 border-red-500' : ''
                        )}>
                          <div className="flex items-center">
                            <div className="text-sm">
                              <p className="font-medium text-gray-900">
                                {a.content}
                              </p>
                            </div>
                          </div>
                          {
                            a.correct ?
                            <CheckCircleIcon
                              className='h-5 w-5 text-green-600'
                              aria-hidden="true"
                            /> : (a.selected && !a.correct) ?
                            <XCircleIcon
                              className='h-5 w-5 text-red-600'
                              aria-hidden="true"
                            /> : <></>
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
          <div className="flex flex-col items-end mt-5">
            {
              status == 'FAIL' ?
                <Link
                  to={`/quiz/${chapterId}`}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Retry Quiz
                </Link>
              : chapterId != 6 ?
              <Link
                to={chapterId < 4 ? `/content/${nextChapter}` : `/final`}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Continue
              </Link> :
              <Link
                to='/'
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Return to home page
              </Link>
            }
          </div>
        </div>
      </>
    </PageWrapper>
  )

}

export default Score