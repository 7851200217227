import React, { useState, useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import ChapterTopicItem from './ChapterTopicItem';
import { getModule, getTopics, getModuleVideo } from '../common/api';
import ModuleLoader from './ModuleLoader';
import ModuleCompleteButton from './ModuleCompleteButton';
import InteractiveVideo from '../common/InteractiveVideo';

const ChapterTopic = () => {
  const [ loading, setLoading ] = useState(false);
  const [ module, setModule ] = useState({})
  const [ topics, setTopics ] = useState([])
  const [ steps, setSteps ] = useOutletContext()
  const [ video, setVideo ] = useState(null);
    
  let { moduleId } = useParams();

  useEffect(() => {

    console.log(steps)

    setLoading(true)

    Promise.all([
      getModule(moduleId),
      getTopics(moduleId)
    ])
    .then((results) => {
      setModule(results[0])
      setTopics(results[1])
      setLoading(false)
    })

    getModuleVideo(moduleId)
    .then((video) => {
      console.log('VIDEO', video)
      if (video.path) {
        setVideo(video)
      } else {
        setVideo(null)
      }
    })
      
  }, [moduleId])

  return (
    
    loading ? <ModuleLoader />
      :
      <div>
        <h1 className="text-2xl font-semibold text-gray-900 py-2">{module.title}</h1>
        {
          topics.map(t => (
            <ChapterTopicItem key={t.id} topic={t} />
          ))
        }

        {
          video &&
          <InteractiveVideo
            videoPath={video.path}
            question={video.question}
            answers={[
              {
                id: 0,
                text: video.incorrectAnswer,
                isCorrect: false
              },
              {
                id: 1,
                text: video.correctAnswer,
                isCorrect: true
              }
            ]}
          />
        }

        <ModuleCompleteButton
          currentModule={moduleId}
          steps={steps}
          setSteps={setSteps}
        />
      </div>
  )

    // return (
    //     <div className="">
    //         <h1 className="text-2xl font-semibold text-gray-900">{module.title}</h1>
    //             {loaded
    //                 ? (
    //                     topic.map((topic) => (
    //                         <ChapterTopicItem
                            
    //                         topic={topic}/>
    //                         ))
                       
    //                     ) : (<div>Loading</div>)
    //                 }
                    
                    
    //     </div>
    // );
};
export default ChapterTopic;