const StudentProgressBadge = ({ student }) => {
  return (
    <>
      {
        student?.completed_at ?
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
          Complete
        </span>
        :
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
          In Progress
        </span>
      }
    </>
  )
}

export default StudentProgressBadge