import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js"
import { useEffect, useState } from "react"
import { LockClosedIcon } from "@heroicons/react/solid"
import { markPaid } from "../common/api"
import { useNavigate } from "react-router-dom"

const CheckoutForm = ({ paymentAmount }) => {
  const stripe = useStripe()
  console.log("stripe", stripe)
  const elements = useElements()

  const [ message, setMessage ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) return;

    setIsLoading(true)

    console.log(process.env.REACT_APP_BASE_URL)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/pay`
      },
    })

    if (error.type == 'card_error' || error.type == 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('An unexpected error occurred.')
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded! Please wait...");
          markPaid(paymentIntent.id)
          .then(() => {
            navigate('/thank-you')
          })
          .catch((err) => {
            navigate('/login')
          })
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });

  }, [stripe]);

  console.log(paymentAmount)

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="flex flex-col items-center mt-5">
        {
          paymentAmount &&
            <button
              type="submit"
              disabled={isLoading || !stripe || !elements}
              id="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              { isLoading ? 'Loading...' : `Pay $${(parseInt(paymentAmount) / 100.0).toFixed(2)} now` }
              <LockClosedIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
          </button>
        }

        { message && <div id="payment-message">{message}</div> }
      </div>

    </form>
  )

}

export default CheckoutForm
