import { RadioGroup } from "@headlessui/react";
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { getQuestions, getFinalExamQuestions, getCompletedChapters, getCertificate } from '../common/api';
import classNames from '../common/twClassNames'
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/solid'
import QuizLoader from "./QuizLoader";
import PageWrapper from "../common/PageWrapper"
import axios from "axios";
import { useRecoilValue } from 'recoil';
import { authAtom } from "../state";

const QTest = ({ finalExam=false }) => {

  const params = useParams()

  const [ error, setError ] = useState(null)
  const [ badQuestions, setBadQuestions ] = useState(new Set([]))
  const [ questions, setQuestions ] = useState([])
	const [ loading, setLoading ] = useState(false)
  const [ studentAnswers, setStudentAnswers ] = useState({})
  const [ scoring, setScoring ] = useState(false)
  const [ quizResults, setQuizResults ] = useState(null)
  const [ chapterDone, setChapterDone ] = useState(false)
  const [ completedChapters, setCompletedChapters ] = useState([])
  const [ certificate, setCertificate ] = useState(null)

  const navigate = useNavigate()

  const auth = useRecoilValue(authAtom)

  const scoreTest = () => {

    setScoring(true)

    console.log(studentAnswers)
    console.log(Object.keys(studentAnswers).map(qid => ({
      question: qid,
      answer: studentAnswers[qid]
    })))

    axios.post('/api/quiz/score', {
      studentId: auth.id,
      chapter: (finalExam ? 6 : params.chapterId),
      questions: Object.keys(studentAnswers).map(qid => ({
        question: qid,
        answer: studentAnswers[qid]
      }))
    })
    .then((res) => {
      console.log(res.data)

      if (res.data.isFinal && res.data.status == 'PASS') {
        axios.post(`/pdf/certificate`, { studentId: auth.id })
      }

      setScoring(false)
      navigate('/score', {
        state: {
          quizResults: {
            ...res.data,
            chapterId: (finalExam ? 6 : params.chapterId)
          },
          studentId: auth.id
        }
      })
    })
    .catch((err) => {
      console.log(err)
      localStorage.removeItem('user')
    })
  }

  const checkUnanswered = () => {
    const allQuestionIds = questions.map(q => q.id);
    const answeredQuestionIds = Object.keys(studentAnswers).map(qid => parseInt(qid));
    const unanswered = allQuestionIds.filter(qid => !answeredQuestionIds.includes(qid))
    setBadQuestions(new Set(unanswered))
    setError('Please answer all questions.')
    return unanswered.length == 0;
  }

  const validateSubmit = () => {
    if (scoring || loading) {
      return false;
    } else {
      if (!checkUnanswered()) {
        return false;
      } else {
        setError('')
        scoreTest()
      }
    }
    

  }
		
	
  const handleChange = (questionId, answerId) => {
    if (badQuestions.has(parseInt(questionId))) {
      setBadQuestions(new Set([ ...badQuestions ].filter(x => x != questionId)))
    }
    setStudentAnswers({
      ...studentAnswers,
      [questionId]: answerId
    })

  }

  useEffect(() => {
		setLoading(true)

    getCompletedChapters(auth.id)
    .then((completed) => {

      const uniqueCompletedChapters = [...new Set(completed)]
      setCompletedChapters(uniqueCompletedChapters)

      // console.log('completed', uniqueCompletedChapters)
      // console.log(parseInt(params.chapterId))

      if (uniqueCompletedChapters.includes(6)) {
        
        getCertificate(auth.id)
        .then((cert) => {
          setCertificate(cert.url)
          setLoading(false)
        })
      }
      if (uniqueCompletedChapters.includes(parseInt(params.chapterId))) {
        
        setChapterDone(true)
        setLoading(false)
      } else {

        if (finalExam && [1,2,3,4].every(chap => uniqueCompletedChapters.includes(chap))) {
          getFinalExamQuestions(auth.id)
          .then((results) => {
            setQuestions(results)
            setStudentAnswers({})
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            alert('Error loading quiz. Please try again.')
          })
        } else if (!finalExam) {
          getQuestions(params.chapterId)
          .then((results) => {
            setQuestions(results)
            setStudentAnswers({})
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            alert('Error loading quiz. Please try again.')
          })
        } else {
          setLoading(false)
        }

      }
      

      // if (uniqueCompletedChapters.includes(6)) {
      // }
      
      // if (uniqueCompletedChapters.includes(parseInt(params.chapterId))) {

      //   console.log('currnet chapter done')

      //   setChapterDone(true);
      //   setLoading(false);

      // } else {

      //   console.log('in the else')

      // }
    })
		
  }, [params.chapterId])

  return (
    <PageWrapper title={`Chapter ${params.chapterId} Quiz`}>
      {
        loading ?
        <div className="mt-4">
          <QuizLoader />
        </div>
        :
        <>
          {
            (finalExam && completedChapters.includes(6)) ?
            <>
              <h3 className="mt-3 font-medium text-lg">
                You have completed the final exam!
              </h3>
              {
                certificate ?
                  <a
                    href={certificate}
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Download Certificate
                  </a>
                :
                  <>Your certificate should be in your email soon.</>
              }
            </>
            : chapterDone ?
            <>
              <h3 className="mt-3 font-medium text-lg">
                You have already completed the Chapter {params.chapterId} Quiz.<br/>Choose the next chapter from the menu to continue!
              </h3>
            </>
            : (finalExam && completedChapters.length < 4) ?
            <>
              <h3 className="mt-3 font-medium text-lg">
                You must complete all the chapters before taking the final exam!
              </h3>
            </>
            :
            <>
              <p>
                Choose the best answer for each question below.
                You must answer {finalExam ? '14 out of 20' : '7 out of 10'} correctly to pass.
                You may retake this quiz as many times as necessary.
              </p>
              {
                questions.map(q => (
        
                  <div className="mt-4" key={q.id}>
                    <div
                      className={classNames(
                        'bg-white border-2 px-4 py-6 shadow-md sm:p-6 sm:rounded-lg mt-3',
                        badQuestions.has(q.id) ? 'border-red-500' : 'border-gray-100'
                      )}
                    >
        
                      <RadioGroup
                        value={studentAnswers[q.id]}
                        onChange={(e) => handleChange(q.id, e)}
                        disabled={scoring || quizResults}
                      >
                        <h2 className="mt-1 mb-2 text-lg text-gray-900">
                          {q.question}
                          
                        </h2>
                        <RadioGroup.Label className="sr-only">{q.question}</RadioGroup.Label>
                        <div className="space-y-4">
                          {
                            q.answers.map(a => (
                              <RadioGroup.Option
                                key={a.id}
                                value={a.id}
                                className={({ checked, active }) =>
                                  classNames(
                                    checked ? 'border-transparent' : 'border-gray-300',
                                    active ? 'border-indigo-500 border-2' : '',
                                    'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
                                  )
                                }
                              >
        
                                {({ active, checked }) => (
                                  <>
                                    <div className="flex items-center">
                                      <div className="text-sm">
                                        <RadioGroup.Label as="p" className="font-medium text-gray-900">
                                          {a.content}
                                        </RadioGroup.Label>
                                      </div>
                                    </div>
                                    <CheckCircleIcon
                                      className={classNames(
                                        !checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600'
                                      )}
                                      aria-hidden="true"
                                    />
                                    <div
                                      className={classNames(
                                        active ? 'border' : 'border-2',
                                        checked ? 'border-indigo-500' : 'border-transparent',
                                        'absolute -inset-px rounded-lg pointer-events-none'
                                      )}
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
        
                              </RadioGroup.Option>
                            ))
                          }
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                ))
              }
              <div className="flex flex-col items-end mt-5">
                <button
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type='button'
                  onClick={validateSubmit}
                  // disabled={scoring}
                >
                  Submit Quiz <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" />
                </button>
                {
                  error &&
                    <p className="mt-3 text-red-500">
                      {error}
                    </p>
                }
              </div>
            </>
          }
        </>

      }
    </PageWrapper>
  )

}

export default QTest