import { useState, useEffect } from "react"
import NavItems from "./NavItems"
import DesktopSidebar from "../common/DesktopSidebar"
import MobileSidebar from "../common/MobileSidebar"
import { getCertificate } from "../common/api"
import { useRecoilValue } from 'recoil';
import { authAtom } from "../state";
import {
  HomeIcon,
  DocumentTextIcon,
  CheckCircleIcon
} from '@heroicons/react/solid';

const StudentSidebars = ({ toggleSidebar, sidebarOpen, completedChapters, setCompletedChapters }) => {

  const [ navItems, setNavItems ] = useState(NavItems)
  const [ certificates, setCertificates ] = useState(null)

  const auth = useRecoilValue(authAtom)

  useEffect(() => {

    const newNavItems = navItems.map(n => {
      if (n.chapter_id) {
        return {
          ...n,
          icon: completedChapters.has(n.chapter_id) ? CheckCircleIcon : DocumentTextIcon
        }
      }
      return n
    })

    setNavItems(newNavItems)

    getCertificate(auth.id).then((certs) => {
      console.log('certs', certs)
      if (certs && certs.url !== null) {
        setCertificates(certs)
      }
    })
    console.log(certificates)
    
    if (certificates || completedChapters.has(6)) {
      
      console.log("completed",completedChapters)
      let certItem
      if (completedChapters.has(6)) {
        certItem = {
          name: 'Certificates', path: '/certificate', icon: DocumentTextIcon, current: false  
        }
        setNavItems([certItem]);
      } else {
        setNavItems([...navItems, certItem]);
      }
      
    }
    
  }, [ completedChapters, certificates ])

  return (
    <>
      <MobileSidebar
        toggleSidebar={toggleSidebar}
        sidebarOpen={sidebarOpen}
        navItems={navItems}
      />

      <DesktopSidebar navItems={navItems} />

    </>
  )
}

export default StudentSidebars