import axios from "axios";

const getCounties = () => {
  return new Promise((resolve, reject) => {
    axios.get('/public/counties')
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getCourts = (countyId=null) => {
  return new Promise((resolve, reject) => {
    const qp = countyId ? `?countyId=${countyId}` : '';
    axios.get(`/public/courts${qp}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getPaymentIntent = (studentId) => {
  return new Promise((resolve, reject) => {
    axios.post('/public/payment/intent', { studentId: studentId })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const recordPayment = (studentId, paymentIntent, piClientSecret) => {
  return new Promise((resolve, reject) => {
    axios.post('/public/payment/record', {
      studentId: studentId,
      paymentIntent: paymentIntent,
      paymentIntentClientSecret: piClientSecret
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const markPaid = (paymentIntent) => {
  return new Promise((resolve, reject) => {
    axios.post('/public/payment/mark-paid', {
      paymentIntent: paymentIntent
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getUser = (userId, userType) => {
  return new Promise((resolve, reject) => {
    resolve({})
  })
}

const getModule = (moduleId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/content/module/${moduleId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getModules = (chapterId, userId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/content/modules/${chapterId}?userId=${userId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getTopics = (moduleId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/content/topics/${moduleId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getTopic = (topicId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/content/topic/${topicId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const completeModule = (studentId, moduleId) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/students/moduleComplete', {
      studentId: studentId,
      moduleId: moduleId
    })
    .then(() => resolve())
    .catch((err) => reject(err))
  })
}

const completeChapter = (studentId, chapterId) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/students/chapterComplete', {
      studentId: studentId,
      chapterId: chapterId
    })
    .then(() => resolve())
    .catch((err) => reject(err))
  })
}
const getCompletedChapters = (studentId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/students/${studentId}/completedChapters`)
    .then((res) => {
      // console.log(res.data)
      resolve(res.data)
    })
    .catch((err) => reject(err))
  })
}
const getQuestions = (chapterId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/quiz/questions/${chapterId}`, {
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getAllQuestions = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/content/questions`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getAnswersFromQuestionIds = (questionIds) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/quiz/answers/${questionIds}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}
const getChapters = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/content/chapters`, {
    })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getFinalExamQuestions = (studentId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/quiz/finalExamQuestions/${studentId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}
const getAnswers = (studentId, questionId, answerId, chapterId) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/quiz/score', {
      studentId: studentId,
      questionId: questionId,
      answerId: answerId,
      chapterId: chapterId

    })
    .then(() => resolve())
    .catch((err) => reject(err))
  })
}

const getPaymentAmountForStudent = (studentId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/public/payment/${studentId}/paymentAmount`)
    .then((res) => resolve(res.data.paymentAmount))
    .catch((err) => reject(err))
  })
}

const checkQuizStatus = (studentId, chapterId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/students/${studentId}/quizStatus/${chapterId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getModuleVideo = (moduleId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/content/video/${moduleId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getCertificate = (studentId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/students/${studentId}/certificate`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}
const getCertificateAll = (studentId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/students/${studentId}/certificate/all`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const resetCourse = (studentId) => {
  return new Promise((resolve, reject) => {
    axios.put(`/api/students/resetcourse/${studentId}`)
    .then((res) => resolve(res))
    .catch((err) => reject(err))
  })
}

export {
  getCounties,
  getCourts,
  getPaymentIntent,
  recordPayment,
  getUser,
  getModule,
  getModules,
  getTopics,
  getTopic,
  completeModule,
  completeChapter,
  getCompletedChapters,
  getQuestions,
  getChapters,
  getFinalExamQuestions,
  getAnswers,
  getAnswersFromQuestionIds,
  getPaymentAmountForStudent,
  checkQuizStatus,
  markPaid,
  getModuleVideo,
  getCertificate,
  getCertificateAll,
  getAllQuestions,
  resetCourse
}