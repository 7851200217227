import axios from "axios";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { getCourts } from "../common/api";
import listStates from "../common/listStates";

const StudentSignUp = () => {

  const [ error, setError ] = useState(false)
	const [ loading, setLoading ] = useState(false)
	const [ courts, setCourts ] = useState([]);
	const [ inputErrors, setInputErrors ] = useState(new Set())
	const [ studentData, setStudentData ] = useState({
		email: '',
		dl_number: '',
		first_name: '',
		last_name: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		dob: '',
		court: ''
	})

  const navigate = useNavigate()

  useEffect(() => {
		getCourts()
		.then((courts) => {
			setCourts((courts))
			setStudentData({
				...studentData,
				court: courts[0].id
			})
		})
		.catch((err) => {
			console.log(err)
			alert('error getting court list')
		})
  }, [])

	const handleChange = (e) => {
		const value = e.target.value
		setStudentData({
			...studentData,
			[e.target.name]: value
		})
	}

	const handleRegister = (e) => {
		e.preventDefault()

		let currentErrors = []
		if (!studentData.first_name) currentErrors.push('first_name')
		if (!studentData.last_name) currentErrors.push('last_name')
		if (!studentData.email) currentErrors.push('email')
		if (!studentData.dl_number) currentErrors.push('dl_number')
		if (studentData.dob.length != 10) currentErrors.push('dob')
		if (!studentData.address) currentErrors.push('address')
		if (!studentData.city) currentErrors.push('city')
		if (!studentData.state) currentErrors.push('state')
		if (!studentData.zip) currentErrors.push('zip')
		if (!studentData.court) currentErrors.push('court')

		console.log(currentErrors)

		if (currentErrors.length > 0) {
			setInputErrors(new Set(currentErrors))
		} else {
			setInputErrors(new Set())
			setLoading(true)
			axios.post('/register/student', {
        ...studentData,
        backdoor: true
      })
			.then((res) => {
				console.log(res.data)
        navigate(`/admin/students/${res.data.studentId}`)
			})
			.catch((err) => {
				setError(true)
				setLoading(false)
			})
		}
	}


  return (
    <div className="px-4 mt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Student Sign Up</h1>
          <p className="mt-2 text-sm text-gray-700">
            Use this tool to bypass payment &amp; enroll a student manually.
          </p>
          <p className="mt-2 text-sm text-gray-700">
            <em>Please note: if a student already exists with the provided email and/or driver's license number, this will not work.</em>
          </p>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden md:rounded-lg">

              <hr/>

              <form className="space-y-6">


                <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        required
                        placeholder="John"
                        value={studentData.first_name}
                        onChange={handleChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        required
                        placeholder="Doe"
                        value={studentData.last_name}
                        onChange={handleChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        required
                        placeholder="johndoe@gmail.com"
                        value={studentData.email}
                        onChange={handleChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="dl_number" className="block text-sm font-medium text-gray-700">
                      Driver's License Number
                    </label>
                    <div className="mt-1">
                      <input
                        id="dl_number"
                        name="dl_number"
                        type="text"
                        required
                        placeholder="639286738"
                        value={studentData.dl_number}
                        onChange={handleChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                      Date of Birth
                    </label>
                    <div className="mt-1">
                      <InputMask
                        mask='99/99/9999'
                        maskPlaceholder={null}
                        id="dob"
                        name="dob"
                        type="text"
                        required
                        placeholder="07/13/1998"
                        value={studentData.dob}
                        onChange={handleChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                      Address
                    </label>
                    <div className="mt-1">
                      <input
                        id="address"
                        name="address"
                        type="text"
                        required
                        placeholder="1873 White River Way"
                        value={studentData.address}
                        onChange={handleChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                      City
                    </label>
                    <div className="mt-1">
                      <input
                        id="city"
                        name="city"
                        type="text"
                        required
                        placeholder="Salt Lake City"
                        value={studentData.city}
                        onChange={handleChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                      State
                    </label>
                    <div className="mt-1">
                      <select
                        id="state"
                        name="state"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={studentData.state}
                        onChange={handleChange}
                      >
                        {
                          listStates().map(s => (
                            <option key={s} val={s}>{s}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                      Zip
                    </label>
                    <div className="mt-1">
                      <input
                        id="zip"
                        name="zip"
                        type="text"
                        required
                        placeholder="84111"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={studentData.zip}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/* <div className="sm:col-span-6">
                    <label htmlFor="county" className="block text-sm font-medium text-gray-700">
                      County
                    </label>
                    <div className="mt-1">
                      <select
                        id="county"
                        name="county"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={studentData.county}
                        onChange={handleChange}
                      >
                        {
                          counties.map(c => (
                            <option
                              key={c.id}
                              value={c.id}
                            >
                              {c.name}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div> */}

                  <div className="sm:col-span-6">
                    <label htmlFor="court" className="block text-sm font-medium text-gray-700">
                      Court
                    </label>
                    <div className="mt-1">
                      <select
                        id="court"
                        name="court"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={studentData.court}
                        onChange={handleChange}
                      >
                        {
                          courts.map(c => (
                            <option
                              key={c.id}
                              value={c.id}
                            >
                              {c.name}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>



                </div>

                <div>
                  <button
                    onClick={handleRegister}
                    disabled={loading}
                    type="button"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    { loading ? 'Loading...' : 'Register' }
                  </button>
                </div>

                {
                  error &&
                  <div className="w-full text-center">
                    <p className="text-red-500">A user with this email or driver's license number already exists.</p>
                  </div>
                }

                {
                  inputErrors.size > 0 &&
                    <div className="w-full text-center">
                      <p className="text-red-500">Please fill out all fields.</p>
                    </div>
                }
                

              </form>



            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentSignUp