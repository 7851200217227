import { useState } from 'react'
import { MenuIcon } from '@heroicons/react/outline'
import MobileSidebar from '../common/MobileSidebar'
import DesktopSidebar from '../common/DesktopSidebar'
import { Outlet } from 'react-router-dom'
import NavItems from './NavItems'

const AdminApp = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = (open) => {
    setSidebarOpen(open)
  }

  return (
    <>
      <div>

        <MobileSidebar
          toggleSidebar={toggleSidebar}
          sidebarOpen={sidebarOpen}
          navItems={NavItems()}
        />

        <DesktopSidebar navItems={NavItems()} />

        <div className="md:pl-64 flex flex-col flex-1 bg-gray-50 h-screen">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  )
}

export default AdminApp;
