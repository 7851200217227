import { NavLink, useLocation } from "react-router-dom"
import classNames from "../common/twClassNames"

const SidebarLink = ({ item }) => {

  const location = useLocation()
  const isActive = location.pathname == item.path;
  
  return (
    <NavLink
      to={item.path}
      key={item.name}
      className={classNames(
        isActive ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
      )}
    >
      <item.icon
        className={classNames(
          isActive ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
          'mr-4 flex-shrink-0 h-6 w-6'
        )}
      />
      {item.name}
    </NavLink>
  )
}

export default SidebarLink
