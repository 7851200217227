import ContentShimmer from "react-content-shimmer";

const QuizLoader = () => {
  return (
    <>
      <ContentShimmer
        style={{
          width: "100%",
          height: "24px",
          borderRadius: "7px",
        }}
      />
      <ContentShimmer
        rows={3}
        style={{
          width: "75%",
          height: "20px",
          borderRadius: "7px",
          marginTop: "18px",
        }}
      />
    </>
  );
};

export default QuizLoader;
