import classNames from '../common/twClassNames'
import { useNavigate } from 'react-router-dom'
import StudentProgressBadge from './StudentProgressBadge'




const StudentTable = ({ students }) => {
  const navigate = useNavigate()
  


  const openStudent = (studentId) => {
    navigate(`/admin/students/${studentId}`, {
      state: {
        student: students.find(s => s.id == studentId)
      }
    })
  }

  
  return (
    <div className="mt-2 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
         
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    DL #
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Court
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Course Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Registered
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {students.map((student, studentIdx) => (
                  <tr
                    onClick={() => openStudent(student.id)}
                    key={student.id}
                    className={classNames(
                      'cursor-pointer',
                      studentIdx % 2 !== 0 ? 'bg-gray-50' : null
                    )}
                    // className={studentIdx % 2 === 0 ? undefined : 'bg-gray-50'}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {student.first_name} {student.last_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{student.email}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{student.dl_number}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{student.court_name}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <StudentProgressBadge student={student} />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{student.created_at_formatted}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>



  )
}

export default StudentTable;