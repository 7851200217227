import react from "react";
import { useEffect, useState } from "react"
import { getPaymentAmountForStudent, getPaymentIntent, recordPayment } from "../common/api"
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm"
// import { useRecoilValue } from 'recoil';
// import { authAtom } from "../state";
import { useLocation } from "react-router-dom";
import { useUserActions } from "../actions/user.actions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_PUB);
console.log('stripePromise', process.env.PORT)
const Payment = () => {

  const [ clientSecret, setClientSecret ] = useState('')
  const [ paymentAmount, setPaymentAmount ] = useState(null)
  // const auth = useRecoilValue(authAtom)
  const location = useLocation()
  const studentId = location.state?.studentId
  const userActions = useUserActions()

  useEffect(() => {

    userActions.logout()


    const csParam = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (csParam) {
      console.log('setting from csparam', csParam)
      setClientSecret(csParam)
    } else {

      console.log('doing normal flow')
      if (!studentId) return;
      localStorage.setItem('tempStudentId', studentId)

      getPaymentAmountForStudent(studentId)
      .then((studentPaymentAmount) => {
        console.log(studentPaymentAmount)
        setPaymentAmount(studentPaymentAmount)
  
        getPaymentIntent(studentId)
        .then((res) => {
          setClientSecret(res.clientSecret)
        })
        .catch((err) => {
          console.log(err)
          alert('Error initializing payment. Please try again.')
        })
      
        
      })
      .catch((err) => {
        console.log(err)
        alert('Error initializing payment amount. Please try again.')
      })  
    }


  }, [])

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
			<div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-20 w-auto"
						src="https://sd-site-assets.s3.us-west-2.amazonaws.com/images/620d71fcf708f14d9e5d4c46_sd-logo.png"
						alt="Superior Driver"
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Make a Payment</h2>
					<p className="mt-2 text-center text-sm text-gray-600">
            Please make your secure payment to begin the course.
          </p>
				</div>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
					<div className="bg-white py-8 px-4 shadow-md sm:rounded-lg sm:px-10">
            
            {clientSecret &&
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm paymentAmount={paymentAmount} studentId={studentId} />
              </Elements>
            }

          </div>
      </div>
    </>
  )  

}

export default Payment