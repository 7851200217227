import ContentShimmer from 'react-content-shimmer';

const ModuleLoader = () => {

  return (
    <>
      <ContentShimmer
        style={{
          width: '40%',
          height: '36px',
          borderRadius: '7px'
        }}
      />
      <ContentShimmer
        style={{
          width: '30%',
          height: '24px',
          borderRadius: '7px',
          marginTop: '10px'
        }}
      />
      <ContentShimmer
        style={{
          width: '100%',
          height: '350px',
          borderRadius: '7px',
          marginTop: '10px'
        }}
      />
    </>
  )
  
}

export default ModuleLoader