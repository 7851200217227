import React, { useState, useEffect } from "react"

import { getReport, getStats } from '../common/adminApi';

function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}
const Stats = () => {

  const [ stats, setStats ] = useState([])
  const [ report, setReport ] = useState([])

  useEffect(() => {

    Promise.all([getStats(), getReport()])
    .then((results) => {
      console.log(results)
      setStats(results[0])
      setReport(results[1])
    })
    .catch((err) => {
      console.log(err)
      alert('error getting stats')
    })

  }, []);
          
  return (
    <>
      <div className="mb-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Total Last 30 days</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {stats?.map((item) => (
            <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.value}</dd>
            </div>
          ))}
        </dl>
      </div>
      <hr/>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Jurisdiction
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      7 Days
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      30 Days
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      60 Days
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      90 Days
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {
                    report.map((r, rIdx) => (
                      <tr
                        key={r.court_name}
                        className={classNames(
                          rIdx % 2 !== 0 ? 'bg-gray-50' : null
                        )}
                      >
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{r.court_name}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{r.seven_day}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{r.thirty_day}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{r.sixty_day}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{r.ninety_day}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{r.total}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Stats