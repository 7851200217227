import React, { useState, useEffect } from 'react';
import PageWrapper from "../common/PageWrapper";
import { getArchive } from "../common/adminApi";
import { CalendarIcon, IdentificationIcon, MailIcon } from '@heroicons/react/solid';

export default function Archive() {
  const [students, setStudents] = useState(undefined);
  const [filter, setFilter] = useState(undefined);

  const fetchArchiveData = async (filter) => {
    if (!filter) {
      setStudents(undefined);
      return
    }
    try {
      const students = await getArchive(filter);
      if (!students) {
        setStudents([]);
        return;
      }
      setStudents(students);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = () => {
    fetchArchiveData(filter);
  };

  return (
    <div className='p-8'>
      <h1 className='text-xl font-semibold text-gray-900'>Check old certificates</h1>
      <p className='text-sm text-gray-500'>Search for students by drivers licence or email</p>
      <div className="flex items-end gap-3">
        <input
          className="block mt-8 w-1/3 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          type="text"
          placeholder="Drivers licence or Email"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <button
          className="w-fit flex h-fit justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className='flex gap-3 flex-wrap'>
        {students === undefined && <p className='text-sm bg-white mt-8 p-3 rounded-md border text-gray-500'>Enter an email or driver licence to start</p>}
        {students && students.length === 0 && <p className='text-sm mt-8 bg-white p-3 rounded-md border text-gray-500'>No data found</p>}

        {students && students.map((student) => (
          <div className="flex w-[300px] text-gray-600 flex-col gap-3 mt-8 p-4 border bg-white border-gray-200 rounded-md" key={student.id}>
            <div className='flex flex-col justify-between'>
              <div className='flex items-center gap-2'>
                {/* <CalendarIcon className='h-4 w-4 text-gray-400'/> */}
                <svg  xmlns="http://www.w3.org/2000/svg" className='text-blue-500' width="30"  height="30"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-certificate">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
                  <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
                  <path d="M6 9l12 0" /><path d="M6 12l3 0" /><path d="M6 15l2 0" />
                </svg>
                <h2 className='text-lg font-bold'>{new Date(student.generated_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}</h2>
              </div>
              <p className='text-sm font-medium flex items-center gap-2 mt-4'>
                <IdentificationIcon className='h-4 w-4 text-gray-400'/>{student.dl_number}
              </p>
              <p className='text-sm font-medium flex items-center gap-2'>
                <MailIcon className='h-4 w-4 text-gray-400'/>{student.email}
              </p>
              <a 
                className="mt-3 inline-flex items-center border border-transparent text-sm font-medium rounded-md text-indigo-600 underline cursor-pointer"
                href={student.file_url}>Download certificate</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
