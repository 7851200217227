import { CheckCircleIcon } from '@heroicons/react/solid'
import { NavLink, useLocation } from "react-router-dom"

const ModuleNav = ({ modules }) => {

  const location = useLocation()
  console.log(location.pathname)
// Sick
  return (
    <div className="">
      {modules.length > 0 &&
        <h3 className="text-md font-medium mb-3">Chapter {modules[0].chapter_id}</h3>
      }
      <nav aria-label="Progress">
        <ol role="list" className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
          {modules.map((module, stepIdx) => (
            <li key={module.id} className='relative md:flex-1 md:flex'>
              {module.complete ? (
                <NavLink to={module.href} className="group flex items-center w-full">
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                      <CheckCircleIcon className="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">
                      {module.title}
                    </span>
                  </span>
                </NavLink>
              ) : location.pathname.endsWith(`/${module.chapter_id}/${module.id}`) ? (
                <NavLink to={module.href} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full" aria-hidden="true">
                    <span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
                    <span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
                  </span>
                  <span className="ml-3 text-sm font-medium text-indigo-600">{module.title}</span>
                </NavLink>
              ) : (
                <NavLink to={module.href} className="group flex items-center">
                  <span className="px-6 py-4 flex items-center text-sm font-medium">
                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400" aria-hidden="true">
                        <CheckCircleIcon className="w-6 h-6 text-gray-500 " aria-hidden="true" />
                      <span className="text-gray-500 group-hover:text-gray-900" />
                    </span>
                    <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900"> {module.title}</p>
                  </span>
                </NavLink>
              )}
              {stepIdx !== module.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  )
}

export default ModuleNav;