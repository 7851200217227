import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import TestSVG from '../TestSVG';
import { useRecoilValue } from 'recoil';
import { authAtom } from "../state";
import { getCompletedChapters, getCertificate } from '../common/api';
import GoogleTranslate from '../common/GoogleTranslate';

function TrafficCourse() {
  const auth = useRecoilValue(authAtom)
  const [completedChapters, setCompletedChapters] = useState([])
  const [studentRetake, setStudentRetake] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [showTranslate, setShowTranslate] = useState(true)
  const[ redirect, setRedirect ] = useState(null)

  useEffect(() => {
    getCompletedChapters(auth.id)

      .then((completed) => {
        console.log(completed)
        const uniqueCompletedChapters = [...new Set(completed)]
        console.log(uniqueCompletedChapters)
        setCompletedChapters(uniqueCompletedChapters)
        if (uniqueCompletedChapters.includes(6)) {
          setStudentRetake(true)
          setRedirect('/certificate')
        } else {
          setRedirect('/content/1')
        }
      })
      .catch((err) => {
        localStorage.removeItem('user')
        console.log(err)
    })
  }, [])

  
 

  

 

  return (
    <div className="py-4 bg-gray-50 overflow-hidden">
        <GoogleTranslate />
    <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
      <div className="text-base max-w-prose mx-auto lg:max-w-none">
        <h1 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Welcome to Superior Driver Traffic Course!</h1>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          How it works
        </p>
      </div>
      <div className="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
        <p className="text-lg text-gray-500">
        This interactive, online traffic education course is available online 24 hours a day, 7 days a week and can be completed at your own pace. Stop and start, our unique tracking feature will save your place. It's simple, easy and convenient.
        </p>
      </div>
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
        <div className="relative z-10">
            <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Our program works in 3 easy steps:</h2>
            <p>
            This course has been carefully written by prosecutors and has been strategically designed to emphasize certain topics that are most commonly misunderstood by drivers.
            </p>
            <ul role="list">
              <li>Register - Registration takes just a moment. You select the court or jurisdiction that issued the citation and complete the registration.</li>
              <li>Complete the Course - Our course consists of 4 chapters, each with a quiz, and a final exam. You can work at your own pace and logout at anytime to finish later.</li>
              <li>Court is Notified - The court you selected during registration is immediately notified as soon as you complete the course. In most cases, there is nothing else you need to do.</li>
            </ul>
            <p>
            The course material is divided into four chapters. At the end of each chapter you will be required to take and pass a 10 question quiz before you can move on to the following chapter. These 10 questions are randomly selected from the chapter quiz bank. You must answer 7 out of 10 questions correctly to pass the quiz.
            </p>
            <h3>We're here to help</h3>
            <p>
            If you have additional comments or questions, email us at <a href="mailto:chan@superiordriver.com">chan@superiordriver.com</a>.
            </p>
          </div>
          <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none">
            <div className="rounded-md shadow">
              {redirect &&
                <Link
                  to={redirect}
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Begin Course
                </Link>
              }
            </div>
            {/* <div className="rounded-md shadow ml-4">
              <a
                href="#"
                className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50"
              >
                Learn more
              </a>
            </div> */}
          </div>
        </div>
        <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
          <svg
            className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
          </svg>
          <blockquote className="relative  rounded-lg shadow-lg">
            <div className="rounded-t-lg px-1  sm:px-5 sm:pt-5 sm:pb-4">
            <TestSVG />
            </div>
            
          </blockquote>
        </div>
      </div>
    </div>
  </div>
  )
}



export default TrafficCourse;


