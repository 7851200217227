import { CheckIcon, ArrowRightIcon } from '@heroicons/react/solid'
import { completeModule } from '../common/api'
import { useState } from 'react'
import { useRecoilValue } from "recoil";
import { authAtom } from '../state';
import { useNavigate } from 'react-router-dom';

const ModuleCompleteButton = ({ currentModule, steps, setSteps }) => {

  const auth = useRecoilValue(authAtom)
  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(false)
  const currentIsComplete = steps.find(m => m.id == currentModule)?.complete

  const handleNavigate = () => {

    const currentStepIdx = steps.findIndex(m => m.id == currentModule)
    console.log(currentStepIdx)
    console.log(steps)
    const currentChapter = steps[currentStepIdx].chapter_id

    if (currentStepIdx < steps.length - 1) {
      // there is more in this chapter...
      // go to currentStepIdx + 1 at /content/${currentChapter}/${steps[currentStepIdx + 1].id}
      // navigate(`/content/${currentChapter}/${steps[currentStepIdx + 1].id}`)
      // edit: actually just go to steps[currentStepIdx].href
      navigate(steps[currentStepIdx + 1].href)
    } else {
      // we're done with the current chapter
      const currentChapter = steps[currentStepIdx].chapter_id
      if (currentChapter != 4) {
        // we're at the end of the last chapter
        // go to final exam at /final-exam (?)
        navigate('/final-exam')
      } else {
        // there are more chapters left
        // go to next chapter at /content/${currentChapter + 1}
        navigate(`/content/${currentChapter + 1}`)
      }
    }

  }

  const handleClick = (e) => {
    e.preventDefault()
    setLoading(true)

    if (!currentIsComplete) {

      const updatedSteps = steps.map(m => {
        if (m.id == currentModule) {
          return { ...m, complete: true }
        }
        return m
      })

      setSteps(updatedSteps)

      completeModule(auth.id, currentModule)
      .then(() => {
        setLoading(false)
        handleNavigate()
      })
      .catch((err) => {
        console.log(err)
      })
    
    } else {
      handleNavigate()
    }

  }

  return (
    <div className="flex mt-5">
      <button
        disabled={loading}
        onClick={!currentIsComplete ? handleClick : handleNavigate}
        type="button"
        className="flex w-full items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none"
      >
        {
          loading ? 'Loading...'
          : currentIsComplete ?
            <>
              Continue
              <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
            </>
          :
            <>
              Mark Complete
              <CheckIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
            </>
        }
      </button>
    </div>
  )
}

export default ModuleCompleteButton