import React, { useState, useEffect } from "react"
import { useNavigate, Link, Navigate } from "react-router-dom"
import Axios from "axios"
import { getCourts } from '../common/api';
import listStates from "../common/listStates";
import ReactInputDateMask from 'react-input-date-mask';
import InputMask from "react-input-mask";
import axios from "axios";
import { useUserActions } from "../actions/user.actions";
import { authAtom } from "../state"
import { useRecoilValue } from 'recoil'

export default function Register() {

	const [ error, setError ] = useState(false)
	const [ loading, setLoading ] = useState(false)
	const [ courts, setCourts ] = useState([]);
	// const [ counties, setCounties ] = useState([]);

	const auth = useRecoilValue(authAtom)

	const userActions = useUserActions()

	const [ inputErrors, setInputErrors ] = useState(new Set())

	const [ studentData, setStudentData ] = useState({
		email: '',
		dl_number: '',
		first_name: '',
		last_name: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		dob: '',
		court: ''
	})

	const navigate = useNavigate()

	//  onChange event handler to grab state from inputs
	const handleChange = (e) => {
		const value = e.target.value

		setStudentData({
			...studentData,
			[e.target.name]: value
		});


	};
   
	const handleRegister = (e) => {


		e.preventDefault()

		console.log(studentData.dob)

		let currentErrors = []
		if (!studentData.first_name) currentErrors.push('first_name')
		if (!studentData.last_name) currentErrors.push('last_name')
		if (!studentData.email) currentErrors.push('email')
		if (!studentData.dl_number) currentErrors.push('dl_number')
		if (studentData.dob.length != 10) currentErrors.push('dob')
		if (!studentData.address) currentErrors.push('address')
		if (!studentData.city) currentErrors.push('city')
		if (!studentData.state) currentErrors.push('state')
		if (!studentData.zip) currentErrors.push('zip')
		if (!studentData.court) currentErrors.push('court')

		console.log(currentErrors)

		if (currentErrors.length > 0) {
			setInputErrors(new Set(currentErrors))
		} else {
			setInputErrors(new Set())
			setLoading(true)
			axios.post('/register/student', studentData)
			.then((res) => {
				
				console.log(res.data)
				navigate(`/pay`, { state: { studentId: res.data.studentId } })

			})
			.catch((err) => {
				setError(true)
				setLoading(false)
			})
		}
		
	}

	useEffect(() => {

		getCourts()
		.then((courts) => {
			setCourts((courts))

		})
		.catch((err) => {
			console.log(err)
			alert('error getting court list')
		})



	}, []);

	if (auth?.id) return <Navigate to='/' />
   
	return (
		<>
			<div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-12 w-auto"
						src="https://sd-site-assets.s3.us-west-2.amazonaws.com/images/620d71fcf708f14d9e5d4c46_sd-logo.png"
						alt="Workflow"
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create your account</h2>
					<p className="mt-2 text-center text-sm text-gray-600">
						Or{' '}
						<Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
							click here to sign in
						</Link>
					</p>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
					<div className="bg-white py-8 px-4 shadow-md sm:rounded-lg sm:px-10">
						<form className="space-y-6">


							<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								<div className="sm:col-span-3">
									<label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
										First name
									</label>
									<div className="mt-1">
										<input
											id="first_name"
											name="first_name"
											type="text"
											required
											placeholder="John"
											value={studentData.first_name}
											onChange={handleChange}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
										Last name
									</label>
									<div className="mt-1">
										<input
											id="last_name"
											name="last_name"
											type="text"
											required
											placeholder="Doe"
											value={studentData.last_name}
											onChange={handleChange}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="sm:col-span-6">
									<label htmlFor="email" className="block text-sm font-medium text-gray-700">
										Email address
									</label>
									<div className="mt-1">
										<input
											id="email"
											name="email"
											type="text"
											required
											placeholder="johndoe@gmail.com"
											value={studentData.email}
											onChange={handleChange}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label htmlFor="dl_number" className="block text-sm font-medium text-gray-700">
										Driver's License Number
									</label>
									<div className="mt-1">
										<input
											id="dl_number"
											name="dl_number"
											type="text"
											required
											placeholder="639286738"
											value={studentData.dl_number}
											onChange={handleChange}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label htmlFor="dob" className="block text-sm font-medium text-gray-700">
										Date of Birth
									</label>
									<div className="mt-1">
										<InputMask
											mask='99/99/9999'
											maskPlaceholder={null}
											id="dob"
											name="dob"
											type="text"
											required
											placeholder="07/13/1998"
											value={studentData.dob}
											onChange={handleChange}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="sm:col-span-6">
									<label htmlFor="address" className="block text-sm font-medium text-gray-700">
										Address
									</label>
									<div className="mt-1">
										<input
											id="address"
											name="address"
											type="text"
											required
											placeholder="1873 White River Way"
											value={studentData.address}
											onChange={handleChange}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="sm:col-span-2">
									<label htmlFor="city" className="block text-sm font-medium text-gray-700">
										City
									</label>
									<div className="mt-1">
										<input
											id="city"
											name="city"
											type="text"
											required
											placeholder="Salt Lake City"
											value={studentData.city}
											onChange={handleChange}
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										/>
									</div>
								</div>

								<div className="sm:col-span-2">
									<label htmlFor="state" className="block text-sm font-medium text-gray-700">
										State
									</label>
									<div className="mt-1">
										<select
											id="state"
											name="state"
											className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
											value={studentData.state}
											onChange={handleChange}
										>
											{
												listStates().map(s => (
													<option key={s} val={s}>{s}</option>
												))
											}
										</select>
									</div>
								</div>

								<div className="sm:col-span-2">
									<label htmlFor="zip" className="block text-sm font-medium text-gray-700">
										Zip
									</label>
									<div className="mt-1">
										<input
											id="zip"
											name="zip"
											type="text"
											required
											placeholder="84111"
											className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											value={studentData.zip}
											onChange={handleChange}
										/>
									</div>
								</div>

								{/* <div className="sm:col-span-6">
									<label htmlFor="county" className="block text-sm font-medium text-gray-700">
										County
									</label>
									<div className="mt-1">
										<select
											id="county"
											name="county"
											className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
											value={studentData.county}
											onChange={handleChange}
										>
											{
												counties.map(c => (
													<option
														key={c.id}
														value={c.id}
													>
														{c.name}
													</option>
												))
											}
										</select>
									</div>
								</div> */}

								<div className="sm:col-span-6">
									<label htmlFor="court" className="block text-sm font-medium text-gray-700">
										Court
									</label>
									<div className="mt-1">
										<select
											id="court"
											name="court"
											className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
											value={studentData.court}
											onChange={handleChange}
											required
										>
											<option value="" disabled selected>Select a court...</option>
											{
												courts.map(c => (
													<option
														key={c.id}
														value={c.id}
													>
														{c.name}
													</option>
												))
											}
										</select>
									</div>
								</div>



							</div>

							<div>
								<button
									onClick={handleRegister}
									disabled={loading}
									type="button"
									className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									{ loading ? 'Loading...' : 'Register' }
								</button>
							</div>

							{
								error &&
								<div className="w-full text-center">
									<p className="text-red-500">A user with this email or driver's license number already exists.</p>
									<p className="text-indigo-600"><Link to='/login'>Click here to log in instead.</Link></p>
								</div>
							}

							{
								inputErrors.size > 0 &&
									<div className="w-full text-center">
										<p className="text-red-500">Please fill out all fields.</p>
									</div>
							}
							

						</form>
					</div>
				</div>
			</div>
		</>
	)
}
  