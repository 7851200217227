import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline';
import MobileNavItems from './MobileNavItems';
import { useRecoilValue } from "recoil";
import { authAtom } from "../state";
import { useNavigate } from "react-router-dom";
import { useUserActions } from "../actions/user.actions";

const MobileSidebar = ({ navItems, toggleSidebar, sidebarOpen }) => {

  const navigate = useNavigate()

  const auth = useRecoilValue(authAtom)
  const userActions = useUserActions()

  const loginLink = auth?.type == 'admin' ? 'admin-login' : 'login';

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={() => toggleSidebar(true)}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => toggleSidebar(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-15 w-auto"
                  src="https://sd-site-assets.s3.us-west-2.amazonaws.com/images/620d71fcf708f14d9e5d4c46_sd-logo.png"
                  alt="Superior Driver"
                />
              </div>

              <MobileNavItems navItems={navItems} />

            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <a href="#" className="flex-shrink-0 group block">
                <div className="flex items-start flex-col">
                  {/* <div>
                    <img
                      className="inline-block h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div> */}
                  <div
                    className="ml-3"
                    onClick={() => {
                      userActions.logout()
                      navigate(`/${loginLink}`)
                    }}    
                  >
                    <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{auth.name}</p>
                    <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Sign out</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
      </Dialog>
    </Transition.Root>

  )
}

export default MobileSidebar;