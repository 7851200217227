const PageWrapper = ({ title, children }) => {
  return (
    <div className="my-5 bg-gray-50">
      <div className="max-w-full mx-auto sm:px-6 md:px-8">
        {/* <h1 className="text-2xl font-semibold text-gray-900">{title}</h1> */}
      </div>
      <div className="max-w-7xl mx-auto sm:px-6 md:px-8">
        {children}
      </div>
    </div>
  )
}

export default PageWrapper;


