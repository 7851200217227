import PageWrapper from "../common/PageWrapper"
import TestSVG from "../TestSVG"
import TrafficCourse from "./TrafficCourse"
import classNames from '../common/twClassNames'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom"
import Axios from "axios"

const CoursePageWrapper = () => {

  useEffect(() => {
    getChapters()
  }, [])
  
  const [chapters, setChapters] = useState([])
  const [chapterId, setChapterId] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  let params = useParams();

  const getChapters = async () => {
    try {
      const res = await Axios.get('/api/content/chapters')
      setChapters(res.data)
      setLoading(true)
    } catch (err) {
      console.log(err.message)
    }
  }

 
  
  return (
    <PageWrapper title='Superior Driver Traffic Course'>
     
      <TrafficCourse />

    </PageWrapper>
  )

}

export default CoursePageWrapper