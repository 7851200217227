import ContentShimmer from 'react-content-shimmer';

const ModuleNavLoader = () => {

  return (
    <>
      <ContentShimmer
        style={{
          width: '50%',
          height: '24px',
          borderRadius: '7px'
        }}
      />
      <ContentShimmer
        rows={3}
        style={{
          width: '100%',
          height: '20px',
          borderRadius: '7px',
          marginTop: '18px'
        }}
      />
    </>
  )
  
}

export default ModuleNavLoader