/* This example requires Tailwind CSS v2.0+ */
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from '@heroicons/react/solid'

const StudentPagination = ({ studentPage, setStudentPage }) => { 
    const handleNext = event => {
        setStudentPage(studentPage + 1);
    };
    const handlePrevious = event => {
        setStudentPage(studentPage - 1);
    };
  return (
    <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
          {studentPage > 1 &&
              <div className="-mt-px w-0 flex-1 flex">
        
                  <a
                      onClick={handlePrevious}
                      className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
                  >
                      <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                      Previous
                  </a>
              
              </div>
          }
     
      <div className="-mt-px w-0 flex-1 flex justify-end">
        <a
          onClick={handleNext}
          className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer"
        >
          Next
          <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </a>
      </div>
    </nav>
  )
}


export default StudentPagination;