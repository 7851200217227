import { useEffect, useState } from "react";
import { useParams, useOutlet, Outlet } from "react-router-dom";
import PageWrapper from '../common/PageWrapper';
import ModuleNav from './ModuleNav';
import { checkQuizStatus, getModules } from '../common/api';
import { useRecoilValue } from "recoil";
import { authAtom } from '../state';
import ModuleNavLoader from './ModuleNavLoader';
// import ModTest from './ModTest'


const ChapterModule = () => {
  const [ loaded, setLoaded ] = useState(false);
  const [ steps, setSteps ] = useState([])
  let params = useParams();

  const outlet = useOutlet()

  const auth = useRecoilValue(authAtom)

  useEffect(() => {

    setLoaded(false)

    Promise.all([
      getModules(params.chapterId, auth.id),
      checkQuizStatus(auth.id, params.chapterId)
    ])
    .then((results) => {
      let modules = results[0]
      let quizStatus = results[1]
      modules =
      [
        ...modules.map(m => ({
          ...m,
          href: `/content/${m.chapter_id}/${m.id}`
        })),
        {
          id: `${params.chapterId}-quiz`,
          chapter_id: params.chapterId,
          title: `Chapter ${params.chapterId} Quiz`,
          href: `/quiz/${params.chapterId}`,
          complete: quizStatus.complete && quizStatus.status == 'PASS'
        }
      ]
      // console.log(quizStatus)
      setSteps(modules)
      setLoaded(true)
      // console.log(modules)
    })

    getModules(params.chapterId, auth.id)
    .then((modules) => {
    })

  }, [ params.chapterId ])

  return (
    <PageWrapper>

      <div className="">
        <div className="">
          {
            loaded ?
              <ModuleNav modules={steps} />
              : <ModuleNavLoader />
          }
        </div>
        <div className="">
          { outlet ?
            <Outlet context={[steps, setSteps]} />
            :
            <h1 className="text-2xl font-semibold text-gray-900">
              Choose a module to begin!
            </h1>
          }
        </div>
      </div>

    </PageWrapper>
    );
};
export default ChapterModule;
