import React from 'react'

const ChapterTopicItem = ({topic}) => {
  return (
      <div className='prose lg:prose-md prose-img:rounded-xl max-w-6xl container"'>
          <h1>{topic.title}</h1>
          <div className='container justify-center' dangerouslySetInnerHTML={{__html: topic.html_content}}></div>
      
      </div>
  )
}

export default ChapterTopicItem