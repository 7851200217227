import React, { useRef, useState, useEffect } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { useCallback } from "react";
import debounce from "lodash.debounce";
import { getCourts } from '../common/api';

const StudentSearch = ({ studentQuery, setStudentQuery, filterQuery, setFilterQuery }) => {
  const [courts, setCourts] = useState([]);
  
  useEffect(() => {
    getCourts()
      .then((courts) => {
        setCourts([{id: 0, name: 'Please Select One'}, ...courts]);
      })
      .catch((err) => {
        console.log(err);
        alert("error getting court list");
      });
  }, []);

  const inputRef = useRef("");
  const filterRef = useRef("");
  
  const handleQuery = () => {
    setStudentQuery(inputRef.current.value);
  };
  
  const handleFilterQuery = () => {
    setFilterQuery(filterRef.current.value);
    
  };

  const debouncedChangeHandler = useCallback(debounce(handleQuery, 300), []);
  const filterChangeHandler = useCallback(handleFilterQuery, []);

  return (
    <div className="flex mt-3">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {/* <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
      </div>
      <input
        ref={inputRef}
        id="search"
        name="search"
        className="block w-1/3 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        placeholder="Search"
        type="search"
        onChange={debouncedChangeHandler}
      />
      <select
        id="court"
        name="court"
        className="block w-1/3 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mx-4"
        ref={filterRef}
        onChange={filterChangeHandler}
      >
        {courts.map((c) => (
          <option key={c.id} value={c.id}>
            {c.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StudentSearch;
