import { useEffect, useState } from 'react';
import { recordPayment } from '../common/api';
import { useNavigate, Link } from 'react-router-dom';
import { Elements, useStripe } from '@stripe/react-stripe-js';

const ThankYou = () => {

  // const stripe = useStripe()
  // const navigate = useNavigate()

  // const studentId = localStorage.getItem('tempStudentId')
  // const [ validPayment, setValidPayment ] = useState(false)
  // const [ message, setMessage ] = useState('Loading...')

  // useEffect(() => {
  //   console.log('in checkoutform')
  //   if (!stripe) return

  //   console.log('we have stripe')

  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     'payment_intent_client_secret'
  //   )

  //   if (!clientSecret) return;

  //   console.log('we have cs')

  //   console.log('cs:', clientSecret)


  // }, [ stripe ])

  // useEffect(() => {

  //   if (studentId) {

  //     localStorage.removeItem('tempStudentId')
  //     const sp = new URLSearchParams(window.location.search);
  //     const pi = sp.get('payment_intent');
  //     const pics = sp.get('payment_intent_client_secret');
  
  //     if (pics) {
  //       stripe.retrievePaymentIntent(pics)
  //       .then(({ paymentIntent }) => {
  //         switch(paymentIntent.status) {
  //           case 'succeeded':
  //             setMessage('Payment succeeded!')
  //             setValidPayment(true)
  //             recordPayment(studentId, pi, pics)
  //             .then(() => {
  //               console.log('payment recorded')
  //             })      
  //             break
  //           case 'processing':
  //             setMessage('Your payment is processing.')
  //             break
  //           case 'requires_payment_method':
  //             setMessage('Your payment was not successful, please try again.')
  //             break
  //           default:
  //             setMessage('Something went wrong.')
  //             break
  //         }
  //       })  
  //     }
  
  //     // if (pi && pics) {
  //     //   sp.delete('payment_intent')
  //     //   sp.delete('payment_intent_client_secret')
  //     //   recordPayment(studentId, pi, pics)
  //     //   .then(() => {
  //     //     console.log('payment recorded')
  //     //   })
  //     // }

  //   } else {
  //     navigate('/register')
  //   }

  // }, [])

  // if (!studentId) return <Navigate to='/register' />

  return (
    <>
			<div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-20 w-auto"
						src="https://sd-site-assets.s3.us-west-2.amazonaws.com/images/620d71fcf708f14d9e5d4c46_sd-logo.png"
						alt="Superior Driver"
					/>
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Thank you!</h2>
            <p className="mt-2 text-center text-md font-semibold text-gray-600">
              Please <Link to='/login' className='text-indigo-600 hover:text-indigo-700'>click here to log in and begin the course</Link>.
            </p>
				</div>
      </div>
    </>
  )
}

export default ThankYou