import PageWrapper from "../common/PageWrapper";
import Stats from "./Stats";
import { useRecoilValue } from "recoil";
import { authAtom } from '../state';
import { useEffect } from "react";

const AdminDashboard = () => {
  const auth = useRecoilValue(authAtom)

  useEffect(() => {
    console.log(auth)
  }, [])

  return (
    <PageWrapper title="Dashboard">
      
      {
        !auth?.court_id &&
          <Stats />
      }
      
    </PageWrapper>
  )
}

export default AdminDashboard;
