import { atom } from 'recoil';

let user = null;

try {
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    user = JSON.parse(storedUser);
    
    if (user && typeof user === 'object') {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }
} catch (error) {
  console.error('Invalid JSON format in localStorage:', error);
  localStorage.removeItem('user');
}

const authAtom = atom({
  key: 'auth',
  default: user,
});

export { authAtom };
