import React from 'react'
import classNames from '../common/twClassNames'
import { Fragment } from 'react'
import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { getQuestions, getChapters, getAllQuestions } from '../common/api';

const QuestionList = () => {
 
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [chapters, setChapters] = useState([])
    const params = useParams()
    const navigate = useNavigate()

    const openQuestion = (questionId) => {
      navigate(`${questionId}`, {
        state: {
          question: questions.find(q => q.id == questionId)
        }
      })
    }

    useEffect(() => {
      getChapters()
      .then((res) => {
        console.log(res)
        setChapters(res)

        getAllQuestions()
        .then((qRes) => {
          console.log(qRes);
          setQuestions(qRes);
        })
      })
    }, [])
  
  //     useEffect(() => {
  //       getChapters()
  //         .then((res) => {
  //             setChapters(res)
  //             getQuestions(1).then((response) => {
  //                 setQuestions(response)
  //             })
	// 	})
      
  //   .catch((err) => {
  //     console.log(err)
      
  //   })
		
  // }, [])

    

  return (
    <div className="px-4 mt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Chapter Questions</h1>
          {/* <p className="mt-2 text-sm text-gray-700">
            A list of all the questions.
          </p> */}
        </div>
        {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Question
          </button>
        </div> */}
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Question
                    </th>
                   
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {chapters?.map((chapter) => (
                    <Fragment key={chapter.id}>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-gray-200 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          {chapter.title} - {chapter.description}
                        </th>
                      </tr>
                      {questions
                        .filter(q => q.chapter_id == chapter.id)
                        .map((question, index) => (
                        <tr
                          key={index}
                          className={classNames(index === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {question.question.substring(0, 120)}...
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                          <td className="cursor-pointer relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              onClick={() => {
                                openQuestion(question.id)
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Edit<span className="sr-only"> {question.id}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionList