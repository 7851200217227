import { PaperClipIcon } from "@heroicons/react/solid"
import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { getStudent, ninjaMarkPaid, generateCert } from "../common/adminApi"
import { getCertificate } from "../common/api"
import PageWrapper from '../common/PageWrapper'
import StudentProgressBadge from "./StudentProgressBadge"
import { useRecoilValue } from "recoil";
import { authAtom } from '../state';


const Student = () => {
  const location = useLocation()
  const params = useParams()

  const [ studentId, setStudentId ] = useState(location.state?.student?.id ?? params.studentId)
  const [ student, setStudent ] = useState(null)
  const [ certUrl, setCertUrl ] = useState(null)
  const [ markPaidLoading, setMarkPaidLoading ] = useState(false)
  const [ completionDate, setCompletionDate ] = useState("")
  const [ certLoading, setCertLoading ] = useState(false)

  const auth = useRecoilValue(authAtom)
  console.log(auth)

  useEffect(() => {

    Promise.all([
      getStudent(studentId),
      getCertificate(studentId)
    ])
    .then((results) => {
      setStudent(results[0])
      setCertUrl(results[1].url)
    })
    .catch((err) => {
      console.log(err)
      alert('Error getting student details')
    })

    // getStudent(params.studentId)
    // .then((student) => {
    //   setStudent(student)
    // })
    // .catch((err) => {
    //   console.log(err)
    //   alert('Error getting student details')
    // })
  }, [ studentId ])

  const changeCompletionDate = (e) => {
    setCompletionDate(e.target.value)
  }

  const handleGenerateCert = () => {
    setCertLoading(true)
    generateCert(studentId, completionDate ?? null)
    .then(() => {
      window.location.reload(false);
    })
  }

  const handleMarkPaid = () => {
    setMarkPaidLoading(true)
    ninjaMarkPaid(auth.id, studentId)
    .then(() => {
      getStudent(studentId)
      .then((newStudent) => setStudent(newStudent))
      .catch((err) => {
        console.log(err)
        location.reload()
      })
    })
    .catch((err) => {
      alert('Error marking student as paid')
    })
  }

  return (
    <PageWrapper title={`${student?.first_name} ${student?.last_name}`}>
      {/* <h1>{student.first_name} {student.last_name}</h1> */}

      <div className="flex justify-between mt-5 pb-3">
        <div>
          <span className="text-gray-500-bold">Course status: <StudentProgressBadge student={student} /></span>
        </div>
        <div>
          <span className="text-gray-500">
            Registered {student?.created_at_formatted}
          </span>
        </div>
      </div>

      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{student?.first_name} {student?.last_name}</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">DL#: {student?.dl_number}</p>
          {
            (!student?.payment_at && auth.type == 'admin') &&
            <>
              <button
                className="flex justify-center py-1 px-2 mt-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleMarkPaid}
              >
                { markPaidLoading ? "Loading..." : "Mark as paid" }
              </button>
            </>
          }
          {
            student?.payment_at &&
              <span className="text-sm text-gray-500">
                Paid on {student?.payment_at_formatted}
              </span>
          }
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
         
          {/* <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Registered On</dt>
            <dd className="mt-1 text-sm text-gray-900">{student.created_at_formatted}</dd>
          </div> */}
            <div className="sm:col-span-1">
              
            <dt className="text-sm font-medium text-gray-500">Email address</dt>
            <dd className="mt-1 text-sm text-gray-900">{student?.email}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Court</dt>
              <dd className="mt-1 text-sm text-gray-900">{student?.court_name}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">DOB</dt>
              <dd className="mt-1 text-sm text-gray-900">{student?.dob_formatted}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900">
              <span>{student?.address}</span>
                <br/>
                <span>{student?.city}, {student?.state} {student?.zip}</span>
              </dd>
          </div>
         
          {
            certUrl &&
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Attachments</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                      <div className="flex w-0 flex-1 items-center">
                        <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <span className="ml-2 w-0 flex-1 truncate">Certificate_Of_Completion.pdf</span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a href={certUrl} className="font-medium text-indigo-600 hover:text-indigo-500">
                          Download
                        </a>
                      </div>
                    </li>
                  
                  </ul>
                </dd>
              </div>
          }

          {auth?.type=== "super_admin" &&<div>
            <input
              id="completionDate"
              name="completionDate"
              type="text"
              placeholder="January 1, 2023"
              onChange={changeCompletionDate}
              value={completionDate}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              disabled={certLoading}
              className="flex justify-center py-1 px-2 mt-2 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleGenerateCert}
            >
              { certLoading ? "Loading..." : "Generate new certificate" }
            </button>
          </div>}

        </dl>
      </div>
    </div>

    </PageWrapper>
  )
}

export default Student