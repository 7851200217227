import DesktopNavItems from "./DesktopNavItems";
import { useRecoilValue } from "recoil";
import { authAtom } from "../state";
import { useUserActions } from "../actions/user.actions";
import { useNavigate } from "react-router-dom";

const DesktopSidebar = ({ navItems }) => {

  const auth = useRecoilValue(authAtom)
  const userActions = useUserActions()
  const navigate = useNavigate()

  const loginLink = auth?.type == 'admin' ? 'admin-login' : 'login';

  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img
              className="h-50 w-auto"
              src="https://sd-site-assets.s3.us-west-2.amazonaws.com/images/620d71fcf708f14d9e5d4c46_sd-logo.png"
              alt="Superior Driver"
            />
          </div>

          <DesktopNavItems navItems={navItems} />
          
        </div>
        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
          <a href="#" className="flex-shrink-0 w-full group block">
            <div className="flex items-start flex-col">
              {/* <div className="ml-3 mb-3" id="google_translate_button"></div> */}
              {/* <div>
                <img
                  className="inline-block h-9 w-9 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </div> */}
              <div
                className="ml-3"
                onClick={() => {
                  userActions.logout()
                  navigate(`/${loginLink}`)
                }}
              >
                <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{auth.name}</p>
                <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">Sign out</p>
              </div>
            </div>
          </a>
        </div>
        
      </div>
    </div>
  )
}

export default DesktopSidebar;