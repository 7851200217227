import axios from "axios"

const changePassword = (userId, currentPw, newPw) => {
  return new Promise((resolve, reject) => {
    axios.post('/admin/change-password', {
      userId: userId,
      currentPw: currentPw,
      newPw: newPw
    })
    .then(() => resolve())
    .catch((err) => reject(err))
  })
}

const ninjaMarkPaid = (userId, studentId) => {
  return new Promise((resolve, reject) => {
    axios.post(`/admin/markPaid/${studentId}`, {
      userId: userId
    })
    .then(() => resolve())
    .catch((err) => reject(err))
  })
}

const getStudents = (params) => {
  return new Promise((resolve, reject) => {
    axios.get('/admin/students', { params })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getStudent = (studentId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/admin/students/${studentId}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getStats = () => {
  return new Promise((resolve, reject) => {
    axios.get('/admin/stats')
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getReport = () => {
  return new Promise((resolve, reject) => {
    axios.get('/admin/report')
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const saveQuestion = (questionId, content) => {
  return new Promise((resolve, reject) => {
    axios.post(`/admin/edit/question/${questionId}`, { content })
    .then(() => resolve())
    .catch((err) => reject(err))
  })
}

const saveAnswer = (answerId, content) => {
  return new Promise((resolve, reject) => {
    axios.post(`/admin/edit/answer/${answerId}`, { content })
    .then(() => resolve())
    .catch((err) => reject(err))
  })
}

const generateCert = (studentId, completionDate) => {
  return new Promise((resolve, reject) => {
    axios.post(`/pdf/certificate?noEmail=1`, { studentId, completionDate })
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}

const getArchive = (filter) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/students/archivedata/${filter}`)
    .then((res) => resolve(res.data))
    .catch((err) => reject(err))
  })
}


export {
  getStudents,
  getStudent,
  getStats,
  saveQuestion,
  saveAnswer,
  changePassword,
  getReport,
  ninjaMarkPaid,
  generateCert,
  getArchive
}