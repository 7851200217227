import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player'
import VideoAnswer from './VideoAnswer'

const InteractiveVideo = ({ videoPath, question, answers }) => {
  
  const [ videoUrl, setVideoUrl ] = useState(`https://sd-interactive-videos.s3.us-west-2.amazonaws.com/${videoPath}/start.mp4`)
  const [ videoComplete, setVideoComplete ] = useState(false)
  const [ selectedAnswer, setSelectedAnswer ] = useState(null)
  const [ videoPlaying, setVideoPlaying ] = useState(false)

  const selectAnswer = (answerId) => {
    const a = answers.find(a => a.id == answerId)
    setSelectedAnswer(a)
    setVideoPlaying(true)

    if (a.isCorrect) {
      setVideoUrl(`https://sd-interactive-videos.s3.us-west-2.amazonaws.com/${videoPath}/correct.mp4`)
    } else {
      setVideoUrl(`https://sd-interactive-videos.s3.us-west-2.amazonaws.com/${videoPath}/incorrect.mp4`)
    }
  }

  useEffect(() => {
    // console.log('re-render?')
    setVideoUrl(`https://sd-interactive-videos.s3.us-west-2.amazonaws.com/${videoPath}/start.mp4`)
  }, [ videoPath ])

  return (
    <div className='my-3 flex flex-col items-center'>

      <h3 className='text-lg mb-2'>Watch the video below, then choose the best answer.</h3>
      
      {/* these two are just loading the correct/incorrect videos behind the scenes for a smoother UX */}
      {/* <ReactPlayer url={`https://sd-interactive-videos.s3.us-west-2.amazonaws.com/${videoPath}/correct.mp4`} style={{display: 'none'}} /> */}
      {/* <ReactPlayer url={`https://sd-interactive-videos.s3.us-west-2.amazonaws.com/${videoPath}/incorrect.mp4`} style={{display: 'none'}} /> */}

      {/* this is the real one; the source URL changes as users select answers */}
      <ReactPlayer
        url={videoUrl}
        width='70%'
        height='100%'
        muted={false}
        playing={videoPlaying}
        controls={true}
        onEnded={() => {
          if (!videoComplete) {
            setVideoComplete(true)
          }
        }}
      />

      <p className='mt-4 text-lg font-bold text-center'>{question}</p>

      {
        videoComplete ?
        <>
          <div className='flex flex-col'>
            {
              answers.map(a => (
                <VideoAnswer
                  key={a.id}
                  id={a.id}
                  text={a.text}
                  isCorrect={a.isCorrect}
                  isSelected={selectedAnswer?.id == a.id}
                  select={selectAnswer}
                  // setVideoUrl={setVideoUrl}
                />
              ))
            }
          </div>
        </> : <p className='text-center text-gray-500'><em>Answer choices will appear after you watch the video.</em></p>
      }

    </div>
  )

}

export default InteractiveVideo