import {
  HomeIcon,
  AcademicCapIcon,
  DocumentTextIcon } from "@heroicons/react/outline";

const NavItems = [
  { name: 'Home', path: '/', icon: HomeIcon, current: true },
  { name: 'Chapter 1', path: '/content/1', icon: DocumentTextIcon, current: false, chapter_id: 1, },
  { name: 'Chapter 2', path: '/content/2', icon: DocumentTextIcon, current: false, chapter_id: 2 },
  { name: 'Chapter 3', path: '/content/3', icon: DocumentTextIcon, current: false, chapter_id: 3 },
  { name: 'Chapter 4', path: '/content/4', icon: DocumentTextIcon, current: false, chapter_id: 4 },
  { name: 'Final Exam', path: '/final', icon: AcademicCapIcon, current: false, chapter_id: 6 },
]

export default NavItems;


// {
//   name: 'Team',
//   current: false,
//   children: [
//     { name: 'Overview', href: '#' },
//     { name: 'Members', href: '#' },
//     { name: 'Calendar', href: '#' },
//     { name: 'Settings', href: '#' },
//   ],
// },