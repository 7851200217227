import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Components/Login/Login';
import Register from './Components/Login/Register';
import ChapterModule from './Components/Courses/ChapterModule';
import ChapterTopic from './Components/Courses/ChapterTopic'

import AdminApp from './Components/AdminApp/App'
import AdminDashboard from './Components/AdminApp/AdminDashboard'
import Students from './Components/AdminApp/Students';
import Student from './Components/AdminApp/Student';
import Payment from './Components/Payment/Payment';
import ThankYou from './Components/Payment/ThankYou';
import StudentApp from './Components/StudentApp/App';
import PrivateRoute from './Components/PrivateRoute';
import CoursePageWrapper from './Components/Courses/CoursePageWrapper';

import QTest from './Components/Quiz/QTest';
import Score from './Components/Quiz/Score';
import TestSVG from './Components/TestSVG';
import QuestionList from './Components/AdminApp/QuestionList';
import QuestionEdit from './Components/AdminApp/QuestionEdit';
import StudentSignUp from './Components/AdminApp/StudentSignUp';
import ChangePassword from './Components/AdminApp/ChangePassword';
import Certificates from './Components/Certificate/Certificates';
import Archive from './Components/AdminApp/Archive';



function App() {

  return (

    <Router>
      <Routes>
      
        {/* routes that don't need to be wrapped in anything, or authenticated */}
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='admin-login' element={<Login admin={true} />} />

        {/* admin routes, wrapped in AdminApp sidebar layout */}
        <Route path='/admin'
          element={
            <PrivateRoute userType='admin'>
              <AdminApp />
            </PrivateRoute>
          }
        >
          <Route index element={<AdminDashboard />} />
          <Route path='modify-questions' element={<QuestionList />} />
          <Route path='modify-questions/:questionId' element={<QuestionEdit />} />
          <Route path='students' element={<Students />} />
          <Route path='student-sign-up' element={<StudentSignUp />} />
          <Route path='students/:studentId' element={<Student />} />
          <Route path='change-password' element={<ChangePassword />} />
          <Route path='archive' element={<Archive />} />
        </Route>
        
        {/* these routes are wrapped in StudentApp sidebar layout */}
        <Route
          path='/'
          element={
            <PrivateRoute userType='student'>
              <StudentApp />
            </PrivateRoute>
          }
        >
          {/* student dashboard home */}
          <Route path='/' element={<CoursePageWrapper />} />

          {/* content paths */}
          <Route path='content/:chapterId' element={<ChapterModule />} >
            <Route path=':moduleId' element={<ChapterTopic />} />
          </Route>

          {/* final exam path */}
          <Route path='/final' element={<QTest finalExam={true} />} />

          <Route path='/certificate' element={<Certificates />} />

          {/* quiz path */}

          {/* <Route exact path='quiz' element={<Quiz/>} />  */}
          <Route path='quiz/:chapterId' element={<QTest />} />
          <Route path='/score' element={<Score />} />

          <Route path='/video-test' element={<TestSVG />} />

        </Route>

        {/* payment paths */}
        <Route path='pay' element={<Payment />} />
        <Route path='thank-you' element={<ThankYou />} />

      </Routes>
      
      
    </Router>
    
    /* // <Router>
    //   <AuthProvider>
    //     <SideNav />
    //     <Routes>
    //       <Route exact path='/' element={<PrivateRoute/>}>
    //         <Route exact path='/' element={<Dashboard/>}/>
    //       </Route>
    //       <Route path="/login" element={<Login />} />
    //       <Route path="/register" element={<Register />} />
    //       <Route path="/reset-password" element={<ResetPassword />} />
    //       <Route exact path='/quiz' element={<PrivateRoute/>}>
    //         <Route exact path='/quiz' element={<Quiz/>}/>
    //       </Route>
    //     </Routes>
    //   </AuthProvider>
    // </Router> */
     
  );
}

export default App;
