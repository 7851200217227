// import NavItems from "../AdminApp/NavItems"
import SidebarLink from "./SidebarLink"

const MobileNavItems = ({ navItems }) => {
  return (
    <nav className="mt-5 px-2 space-y-1">
      {navItems.map((item) => (
        <SidebarLink key={item.path} item={item} />
      ))}
    </nav>
  )
}

export default MobileNavItems;