import { LockClosedIcon } from "@heroicons/react/outline"
import { useState } from "react"
import { changePassword } from "../common/adminApi"
import { useRecoilValue } from "recoil";
import { authAtom } from '../state';

const ChangePassword = () => {
  
  const auth = useRecoilValue(authAtom)

  const [ pwData, setPwData ] = useState({
    current: '',
    newPw: '',
    confirmNew: ''
  })

  const [ loading, setLoading ] = useState(false)

  const handleChange = (e) => {
    setPwData({
      ...pwData,
      [e.target.name]: e.target.value
    })
  }

  const validatePassword = () => {
    return pwData.newPw == pwData.confirmNew;
  }

  // Test123: $2b$10$B39JUvXUNlPFkc7xhmvczecDSFjbaa19DGJNM3FF9hzwYlHpZeCo6

  const handleChangePassword = () => {
    setLoading(true)
    if (!validatePassword()) {
      setLoading(false)
      alert('New Password & Confirm New Password must match')
    } else {
      changePassword(auth.id, pwData.current, pwData.newPw)
      .then(() => {
        alert('Your password was successfully changed!')
        setPwData({
          current: '',
          newPw: '',
          confirmNew: ''
        })
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        alert('There was an error changing your password. Please ensure your current password was typed correctly.')
      })
    }
  }
  
  return (
    <div className="px-4 mt-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Change Password</h1>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden md:rounded-lg">

              <form className="space-y-6">

                <div className="sm:col-span-6">
                  <label htmlFor="current" className="block text-sm font-medium text-gray-700">
                    Current password
                  </label>
                  <div className="mt-1">
                    <input
                      id="current"
                      name="current"
                      type="password"
                      required
                      placeholder="**********"
                      value={pwData.current}
                      onChange={handleChange}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="newPw" className="block text-sm font-medium text-gray-700">
                    New password
                  </label>
                  <div className="mt-1">
                    <input
                      id="newPw"
                      name="newPw"
                      type="password"
                      required
                      placeholder="**********"
                      value={pwData.newPw}
                      onChange={handleChange}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="confirmNew" className="block text-sm font-medium text-gray-700">
                    Confirm new password
                  </label>
                  <div className="mt-1">
                    <input
                      id="confirmNew"
                      name="confirmNew"
                      type="password"
                      required
                      placeholder="**********"
                      value={pwData.confirmNew}
                      onChange={handleChange}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="button"
                    onClick={handleChangePassword}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    { loading ? 'Loading...' : <><LockClosedIcon className="w-5 h-5 mr-2" /> Save new password</> }
                  </button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword