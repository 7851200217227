import { useState } from 'react'
import ReactPlayer from 'react-player'
import InteractiveVideo from './common/InteractiveVideo';
import PageWrapper from './common/PageWrapper';

const TestSVG = () => {

  const [ answerStatus, setAnswerStatus ] = useState('NA');
  const [ videoUrl, setVideoUrl ] = useState('/heck1.mp4');

  const checkAnswer = (isGood) => {
    if (isGood) {
      setVideoUrl('/heck1.mov');
      return;
    }
    setVideoUrl('/heck1.mp4');
  }

  return (

    <PageWrapper>
      <InteractiveVideo
        videoPath='winter-road'
        question='What should the yellow car do when approaching the crosswalk?'
        answers={[
          {
            id: 0,
            text: 'Continue driving at the same speed.',
            isCorrect: false
          },
          {
            id: 1,
            text: 'Yield, check for pedestrians, and stop if necessary.',
            isCorrect: true
          }
        ]}
      />
    </PageWrapper>

  )

}

export default TestSVG