// import NavItems from "./NavItems";
import SidebarLink from "./SidebarLink";


const DesktopNavItems = ({ navItems }) => {

  if (!navItems) {
    console.log(navItems)
    console.log('no nav items')
    localStorage.removeItem('user')
    return null;
  }
  let content;

  try {
    content = navItems.map((item) => (
      <SidebarLink key={item.path} item={item} />
    ));
  } catch (error) {
    localStorage.removeItem('user')
    console.error('Error in DesktopNavItems:', error);
    window.location.reload();
    content = <div>Failed to load navigation items</div>; // Optional: Render a fallback UI
  }
  return (
    <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
      {content}
    </nav>
  )
}

export default DesktopNavItems;