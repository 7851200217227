import React from 'react'
import { useLocation, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { getAnswersFromQuestionIds } from '../common/api'
import PageWrapper from '../common/PageWrapper'
import { saveQuestion, saveAnswer } from '../common/adminApi'




const QuestionEdit = () => {
  const params = useParams()
  const [answers, setAnswers] = useState([])

  const location = useLocation();

  const [question, setQuestion] = useState(location.state?.question)


  useEffect(() => {
    getAnswersFromQuestionIds(params.questionId)
      .then((response) => {
        setAnswers(response)
      })
      .catch((err) => {
    })
  }, [])


  const questionChange = (e) => {
    setQuestion({...question, question: e.target.value})
  }

  const answerChange = (e) => {
    const newAnswers = answers.map(a => {
      if (a.id == e.target.name) {
        return { ...a, content: e.target.value }
      }
      return a
    })
    setAnswers(newAnswers)
  }

  const clickSaveQ = () => {
    saveQuestion(question?.id, question.question)
    .then(() => {
      alert('Question saved!')
    })
    .catch((err) => {
      console.log(err);
      alert('Error saving question. Please try again')
    })
  }

  const clickSaveA = (answerId) => {
    saveAnswer(answerId, answers.find(a => a.id == answerId)?.content)
    .then(() => {
      alert('Answer saved!')
    })
    .catch((err) => {
      console.log(err);
      alert('Error saving answer. Please try again')
    })
  }

    return (
      <PageWrapper>
     <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Edit Question {params.questionId}, Chapter {question?.chapter_id}</h3>
      </div>
      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Question</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <textarea
                onChange={questionChange}
                className="flex-grow"
                rows="3"
                value={question?.question}
              >
              </textarea>
              <span className="flex-grow">
              </span>
              <span className="ml-4 flex-shrink-0">
                <button
                  onClick={() => clickSaveQ()}
                  type="button"
                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Save
                </button>
              </span>
            </dd>
          </div>

          {
            answers.map(a => (
              <div key={a.id} className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">Answer</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <textarea
                    name={a.id}
                    onChange={answerChange}
                    className="flex-grow"
                    value={a.content}
                  >
                  </textarea>
                  <span className="ml-4 flex-shrink-0">
                    <button
                      onClick={() => clickSaveA(a.id)}
                      type="button"
                      className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      // onClick={() => saveAnswer(a.id)}
                    >
                      Save
                    </button>
                  </span>
                </dd>
              </div>
            ))
          }



        </dl>
            </div>
    </PageWrapper>
  )
}

export default QuestionEdit