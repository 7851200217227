import classNames from '../common/twClassNames'

const VideoAnswer = ({ id, text, select, isCorrect, isSelected }) => {

  return (
    <button
      onClick={() => select(id)}
      className={classNames('p-3 my-2 border border-2 rounded',
        isSelected ? isCorrect ? 'border-green-500' : 'border-red-500' : ''
      )}>
      {text}
    </button>
  )

}

export default VideoAnswer