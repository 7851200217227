import {
  HomeIcon,
  UsersIcon,
  InboxIcon,
  ChartBarIcon, 
  FolderAddIcon,
  LockClosedIcon,
  ArchiveIcon,
} from "@heroicons/react/outline";

import { authAtom } from "../state";
import { useRecoilValue } from 'recoil';

const NavItems = () => {

  const auth = useRecoilValue(authAtom)
  console.log(auth);

  return auth?.court_id ? [
    { name: 'Students', path: '/admin/students', icon: UsersIcon, current: false },
    { name: 'Change Password', path: '/admin/change-password', icon: LockClosedIcon, current: false },
  ] : [
    { name: 'Dashboard', path: '/admin', icon: HomeIcon, current: true },
    { name: 'Students', path: '/admin/students', icon: UsersIcon, current: false },
    { name: 'Student Sign Up', path: '/admin/student-sign-up', icon: FolderAddIcon, current: false },
    // { name: 'Submissions', path: '/admin/submissions', icon: InboxIcon, current: false },
    // { name: 'Reports', path: '/admin/reports', icon: ChartBarIcon, current: false },
    { name: 'Modify Questions', path: '/admin/modify-questions', icon: ChartBarIcon, current: false },  
    { name: 'Change Password', path: '/admin/change-password', icon: LockClosedIcon, current: false },
    { name: 'Archive', path: '/admin/archive', icon: ArchiveIcon, current: false }
  ]

}

export default NavItems;
