import {useState, useEffect} from 'react'
import { getCertificateAll, getCompletedChapters } from '../common/api'
import { authAtom } from "../state";
import { useRecoilValue } from 'recoil';
import PageWrapper from '../common/PageWrapper';
import { resetCourse } from "../common/api";
import Alert from "../Alert";

export default function Certificates() {
  const [ certificate, setCertificate ] = useState(null)
  const [showAlert, setShowAlert] = useState(false)
  const [ completedChapters, setCompletedChapters ] = useState([])
  const auth = useRecoilValue(authAtom)

  useEffect(() => {
    getCertificateAll(auth.id)
    .then((res) => {
      setCertificate(res)
    })
    .catch((err) => {
      console.log(err)
      alert('Error fetching certificate. Please try again.')
    })

    getCompletedChapters(auth.id)
    .then((completed) => {

      const uniqueCompletedChapters = [...new Set(completed)]
      setCompletedChapters(uniqueCompletedChapters)
    })
  },[])
  async function resetCourseHandler(studentId) {
    try {
      const response = await resetCourse(studentId)
      if (response.status === 204) {
        localStorage.clear()
        localStorage.removeItem('user')
        window.location.replace('/register')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <PageWrapper>
      {showAlert &&
        <Alert className="max-w-[500px] w-full">
          <p className='text-xl font-bold'>
            Are you sure you want to retake the Course?
          </p>
          <p className='text-sm mt-4'>
            Continuing will result in the loss of your progress, and you will need to register and pay again to retake the course.
          </p>
          <div className='mt-4 flex gap-2 justify-end w-full'>
            <buton 
              onClick={()=> setShowAlert(false)}
              className="border shadow-sm inline-flex text-sm font-medium rounded-md px-4 py-2 cursor-pointer">Cancel</buton>
            <buton
              onClick={()=> resetCourseHandler(auth.id)}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
            >
              Yes, retake course
            </buton>
          </div>
        </Alert>
      }
      <h1 className="text-2xl font-semibold text-gray-900">Certificates</h1>
      
      {completedChapters.includes(6) && 
        <div>
          <p className='mt-8'>You need to retake the course</p>
          <button 
            className="inline-flex mt-4 items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={()=> setShowAlert(true)}>Retake Course</button> 
          <hr className='mt-8'/>
        </div>
      }
      <h2 className='mt-12 font-bold'>Past Certificates</h2>
      <div>
        {certificate && certificate.map((cert)=> {
          return(
            <div className='mt-8'>
              <h2 className='text-sm font-medium'>{new Date(cert.generated_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}</h2>
              <a
                className="mt-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                href={cert.file_url}
              >
                Download Certificate
              </a>
            </div>
          )
        })}
      </div>
    </PageWrapper>
  )
}
