import PageWrapper from "../common/PageWrapper";
import StudentTable from "./StudentTable";
import { useEffect, useState } from 'react'
import { getStudents } from "../common/adminApi";
import StudentSearch from "./StudentSearch";
import StudentPagination from "./StudentPagination";
const Students = () => {

  const [ listStudents, setListStudents ] = useState([])
  const [studentQuery, setStudentQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState(null)
  const [studentPage, setStudentPage] = useState(1)
  
  
  useEffect(() => {
    const params = {
      searchTerm: studentQuery,
      page: studentPage,
      courtId: filterQuery
    }
    getStudents(params)
    .then((students) => {
      console.log(students)
      setListStudents(students)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [studentQuery, studentPage, filterQuery])
  
  return (
    <PageWrapper title='Students' className='mt-5'>
      <StudentSearch studentQuery={studentQuery} setStudentQuery={setStudentQuery} filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
      <StudentTable students={listStudents} />
      <StudentPagination studentPage={studentPage} setStudentPage={setStudentPage}/>
    </PageWrapper>
  )

}

export default Students;