import React from 'react';

export default function Alert({ children, className }) {
  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center`}>
      <div className={`bg-white p-6 shadow-lg rounded ${className}`}>
        {children}
      </div>
    </div>
  );
}
